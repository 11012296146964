@use 'src/pages/management/components/subscriptionManagement/subscriptionManagement.module'
  as *;
@use '@porsche-design-system/components-js/styles' as *;
@use '../../../../components/shared' as *;

.root,
.rootDisabled {
  margin-bottom: $pds-spacing-static-medium;
  display: flex;
}

.rootDisabled {
  pointer-events: none;
}

.tile {
  @extend .contentMainBox;
  cursor: pointer;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;

  @media (min-width: $breakpoint-s-760) {
    flex-wrap: nowrap;
    row-gap: initial;
  }
}

.columnContainer {
  display: flex;
  flex-direction: column;
}

.tag {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  gap: 36px;
  margin-top: $pds-spacing-static-small;
}
