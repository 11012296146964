@use '@porsche-design-system/components-js/styles' as *;
@use 'src/components/shared' as *;

.contentContainer {
  display: flex;
  flex-wrap: nowrap;
}

.imageContainer {
  width: '100%';
  max-width: '500px';
  overflow: 'hidden';

  @media (max-width: $breakpoint-s-760) {
    display: none;
  }
}

.imageTextContainer {
  color: white;
  background-color: black;
  display: flex;
  flex-direction: column;
  padding: $pds-spacing-static-medium;
  border-radius: 0 4px 4px 0;
  position: relative;

  @media (max-width: $breakpoint-s-760) {
    display: none;
  }
}

.textWithIconContainer {
  display: flex;
  margin-bottom: $pds-spacing-static-medium;

  &:last-child {
    margin-bottom: 0;
  }
}

.title,
.text {
  color: white;
}

.text {
  @include pds-heading-medium;
  margin-left: $pds-spacing-static-medium;
  font-weight: 400;
}

.title {
  font-weight: 600;
  @include pds-heading-x-large;

  margin-bottom: $pds-spacing-static-medium;
}

.absoluteColorGradient {
  position: absolute;
  top: 0;
  left: -40px;
  width: 40px;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(2, 0, 36, 0.4) 57%
  );
}

.mobileViewContainer {
  border-radius: 8px;
  width: 100vw;

  @media (min-width: $breakpoint-s-760) {
    display: none;
  }

  .title {
    color: black;
    padding: $pds-spacing-static-medium;
  }
}

.textWithIconContainerMobile {
  display: flex;
  padding: $pds-spacing-static-medium;
  background-color: black;
  height: 100%;
}
